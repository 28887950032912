import { Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import {
  CrmEndpointDecorator,
  CrmEndpointListResponse,
} from 'common-module/endpoint';
import { CrmUserEndpoint, CrmUserService } from 'common-module/user';
import { map, Observable } from 'rxjs';

import { toUserOption, UserOption } from './helpers/to-user-option';
import { User } from './user';

@Injectable({ providedIn: 'root' })
export class UserService extends CrmUserService<User> {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'users',
    endpoint: CrmUserEndpoint<User>,
  })
  override endpoint!: CrmUserEndpoint<User>;

  override login<Body>(body: Body): Observable<User> {
    return super.login(body, {
      headers: { 'x-namespace': 'boge' },
    });
  }

  override listUsers<Params>(
    params?: Params,
  ): Observable<CrmEndpointListResponse<User>> {
    return super.listUsers({
      ...params,
      'roles.anonymised[$exists]': false,
      'roles.system[$exists]': false,
    });
  }

  override resetPassword<Body>(body: Body): Observable<User> {
    return this.endpoint.recovery(body, {
      headers: { 'x-namespace': 'boge' },
      responseType: 'text',
    });
  }

  listUsersAll(params?: CrmDictionary): Observable<User[]> {
    return this.endpoint.listAll({
      params: {
        ...params,
        'roles.anonymised[$exists]': false,
        'roles.system[$exists]': false,
      },
    });
  }

  listUsersOptions(params?: CrmDictionary): Observable<UserOption[]> {
    return this.listUsersAll(params).pipe(
      map((users) => users.map((user) => toUserOption(user))),
    );
  }

  anonymise(id: string): Observable<User> {
    return this.endpoint.request('POST', `${id}/anonymise`);
  }
}
