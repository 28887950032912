import { CrmOption } from 'common-module/common';
import { crmGetUserName } from 'common-module/user';

import { User } from '../user';

export type UserOption = CrmOption<string, User>;

export const toUserOption = (user: User): UserOption => ({
  value: user._id as string,
  label: crmGetUserName(user),
  data: user,
});
